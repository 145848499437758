import React from 'react';
import { employeewise_report, Employeewise_report_export } from '../../request/admin_request.js';
import { Box, Text,Tooltip } from '@chakra-ui/react';
import ReportTable from '../reporttable_componunt/GeneralDataTable';
import BreadCrumb from 'components/navbar/Breadcrumb';
import { Link } from 'react-router-dom';

function toTitleCase(str) {
    if (!str || typeof str !== 'string' || str.trim() === '') {
        return '-';
    }
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
}

const EmployeewiseReport = () => {

    const column = [
        {
            name: <Text as='b'>Name</Text>,
            selector: row => row?.name,
            sortable: true,
            cell: row => (
                // <Text textAlign='left' fontWeight='500' _hover={{fontWeight:'700'}} color='blue.500'  cursor='pointer' onClick={()=>handelDoctorrepport(row.subscriber_id)}>
                //     {row?.name || '-'}
                // </Text>
                <Tooltip label={ `Click to view doctors list`} fontSize='sm'>
                    <Link to={`/report/employeewise_report/${row?.subscriber_id}`} state={{ name: row?.name }} style={{ textDecoration: 'none', color: 'blue' }}>
                        <Text textAlign='left' cursor='pointer'>
                            {toTitleCase(row?.name) || '-'}
                        </Text>
                    </Link >
                 </Tooltip>
            ),
            sortField: 'name',
        },
        {
            name: <Text as='b' >MR Code</Text>,
            selector: row => row?.mr_code,
            sortable: true,
            cell: row => (
                <Text style={{ textAlign: 'center' }}>
                    {row?.mr_code || '-'}
                </Text>
            ),
            sortField: 'mr_code',
        },
        {
            name: <Text as='b' >Designation</Text>,
            selector: row => row?.designation,
            sortable: true,
            cell: row => (
                <Text style={{ textAlign: 'center' }}>
                    {row?.designation || '-'}
                </Text>
            ),
            sortField: 'designation',
        },
        {
            name: <Text as='b' style={{ textAlign: 'center' }}>Total Tests</Text>,
            selector: row => row?.total_tests,
            sortable: true,
            cell: row => (
                <Text style={{ textAlign: 'center' }}>
                    {row?.total_tests || '-'}
                </Text>
            ),
            sortField: 'total_tests',
        },
        {
            name: <Text as='b' style={{ textAlign: 'center' }}>Total Doctor Count</Text>,
            selector: row => row?.total_doctors,
            sortable: true,
            cell: row => (
                <Text style={{ textAlign: 'center' }}>
                    {row?.total_doctors || '-'}
                </Text>
            ),
            sortField: 'total_doctors',
        },
        {
            name: <Text as='b' style={{ textAlign: 'center' }}>Total Camps</Text>,
            selector: row => row?.total_camps,
            sortable: true,
            cell: row => (
                <Text style={{ textAlign: 'center' }}>
                    {row?.total_camps || '-'}
                </Text>
            ),
            sortField: 'total_camps',
        },
        {
            name: <Text as='b' style={{ textAlign: 'center' }}>Last Camp Date</Text>,
            selector: row => row?.last_camp_date
            ,
            sortable: true,
            cell: row => (
                <Text style={{ textAlign: 'center' }}>
                    {row?.last_camp_date || '-'}
                </Text>
            ),
            sortField: 'last_camp_date',
        },
        {
            name: <Text as='b'>HQ</Text>,
            selector: row => row?.hq,
            sortable: true,
            cell: row => (
                <Text style={{ textAlign: 'center' }}>
                    {toTitleCase(row?.hq) || '-'}
                </Text>
            ),
            sortField: 'hq',
        },
        {
            name: <Text as='b'>Region</Text>,
            selector: row => row?.region,
            sortable: true,
            cell: row => (
                <Text style={{ textAlign: 'center' }}>
                    {toTitleCase(row?.region) || '-'}
                </Text>
            ),
            sortField: 'region',
        },
        {
            name: <Text as='b'>Zone</Text>,
            selector: row => row?.zone
            ,
            sortable: true,
            cell: row => (
                <Text style={{ textAlign: 'center' }}>
                    {toTitleCase(row?.zone) || '-'}
                </Text>
            ),
            sortField: 'zone',
        },
    ];

    return (
        <Box p='10px' pt={{ base: "60px", md: "80px", xl: "80px" }}>

            <Box display={{ base: 'block', md: 'none' }} p='5px'>
                <BreadCrumb />
            </Box>

            <ReportTable
                header={'Employeewise report'}
                column={column}
                fetchfun={employeewise_report}
                exportFunction={Employeewise_report_export}
                // exportConsent={TestReportExportConsent}
                countlabel={'Employees'}
                sortablecolm='name'
                showscale={false}
            />
        </Box>
    )
};


export default EmployeewiseReport