import React, { useEffect, useState, useRef } from 'react';
import { Box, Flex, Spacer, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import startOfQuarter from 'date-fns/startOfQuarter';
import { Fetch_DoctorList } from '../request';
import DataTableFrame from 'components/datatable/DataTableFrame';
import Datatablepagination from 'components/pagination/Datatablepagination';
import Divisionfilter from 'components/filterComponunts/Divisionfilter';
import Card from 'components/card/Card';
import Search from 'components/Search/Search';
import Datepicker from '../reporttable_componunt/Componunts/Datepicker';
import LoadingComp from 'components/LodingComp/LoadingComp';
import BreadCrumb from 'components/navbar/Breadcrumb';
import { useDebounce } from 'use-debounce'; // Import use-debounce

function toTitleCase(str) {
    if (!str || typeof str !== 'string' || str.trim() === '') {
        return '-';
    }
    return str.replace(
        /\w\S*/g,
        (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
}

const DoctorList = () => {
    const navigate = useNavigate();
    let { divisions } = JSON.parse(localStorage.getItem('user')) || [];
    let [DrList, setDrlist] = useState([]);
    let [page, setPage] = useState(1);
    let [perpage, setPerpage] = useState(1);
    let [total, setTotal] = useState(0);
    let [Divid, setDivid] = useState(divisions[0].division_id);
    let [filterText, setFilterText] = useState('');
    let [Loading, setLoading] = useState(false);
    const [sortColumn, setSortColumn] = useState('doc_code'); // Default sort column
    const [sortDirection, setSortDirection] = useState('asc'); // Default sort direction
    const isInitialRender = useRef(true);
    const [date, setDate] = useState([
        {
            startDate: startOfQuarter(new Date()),
            endDate: new Date(),
            key: 'selection'
        }
    ]);

    let [endDate, setEndDate] = useState(format(new Date(), 'dd-MM-yyyy').toString());
    let [startDate, setStartDate] = useState(format(startOfQuarter(new Date()), 'dd-MM-yyyy').toString());

    // Use useDebounce to debounce the filterText
    const [debouncedFilterText] = useDebounce(filterText, 500);

    const handelDatefilter = () => {
        let enddivas = format(date[0].endDate, 'dd-MM-yyyy').toString();
        let startdivas = format(date[0].startDate, 'dd-MM-yyyy').toString();
        setStartDate(startdivas);
        setEndDate(enddivas);
    };

    const column = [
        {
            name: <Text as='b'>Dr Code</Text>,
            selector: row => row?.doc_code || '-',
            sortable: true,
            sortField: 'doc_code',
        },
        {
            name: <Text as='b'>Dr Name</Text>,
            selector: row => toTitleCase(row?.doc_name) || '-',
            sortable: true,
            sortField: 'doc_name',
        },
        {
            name: <Text as='b'>Dr Speciality</Text>,
            selector: row => toTitleCase(row?.doc_speciality) || '-',
            sortable: true,
            sortField: 'doc_speciality',
        },
        {
            name: <Text as='b'>MR Code</Text>,
            selector: row => row?.mr_code || '-',
            sortable: true,
            sortField: 'mr_code',
        },
        {
            name: <Text as='b'>MR Name</Text>,
            selector: row => toTitleCase(row?.mr_name) || '-',
            sortable: true,
            sortField: 'mr_name',
        },
    ];

    const getDoctorList = async (type) => {

        try {
            if (type !== 'sort') {
                setLoading(true);
            }
            let data = await Fetch_DoctorList(page, Divid, debouncedFilterText, startDate, endDate, sortColumn, sortDirection);
            if (data.status === 'success') {
                setDrlist(data.data.data);
                setPage(data.data.current_page);
                setPerpage(data.data.per_page);
                setTotal(data.data.total);
            }
            setLoading(false);
        } catch (err) {
            navigate('auth/sign-in');
        }
    };

    useEffect(() => {
        setLoading(true);
        getDoctorList('regular');
    }, [page, Divid, debouncedFilterText, startDate, endDate]);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
        } else {
            // setSortloading(true);
            getDoctorList('sort');
            // setSortloading(false);
        }
    }, [sortDirection]);

    const handleSort = (column, sortDirection) => {
        setSortColumn(column.sortField);
        setSortDirection(sortDirection);
    };

    const handelpagechange = (val) => {
        if (val === page || Loading) {
            return;
        }
        setPage(val);
    };

    const handelSelect = (val) => {
        setDivid(val);
    };

    const handelSearch = (val) => {
        setFilterText(val);
    };

    const handeldate = (val) => {
        setDate(val);
    };

    return (
        <Box p='10px' pt={{ base: "60px", md: "80px", xl: "80px" }}>
            <Box display={{ base: 'block', md: 'none' }} p='5px'>
                <BreadCrumb />
            </Box>

            <Card>
                <Text pl='6px' fontSize='xl'>Doctor List</Text>
                <Flex w='100%' gap='10px' overflow='auto' justifyContent='baseline'>
                    <Divisionfilter
                        Data={divisions}
                        All={false}
                        handelSelect={handelSelect}
                        name='Division Name'
                    />

                    <Datepicker
                        date={date}
                        start_date={startDate}
                        to_date={endDate}
                        handeldate={handeldate}
                        handelDatefilter={handelDatefilter} />
                    <Spacer />

                    <Search filterText={filterText} handelSearch={handelSearch} />
                </Flex>

                <Box>
                    {Loading ? <LoadingComp /> :
                        <DataTableFrame
                            data={DrList}
                            keyvalue='id'
                            column={column}
                            onSort={handleSort} // Pass the sorting handler
                            sortServer // Tell DataTableFrame that sorting is handled by the server
                            sortColumn={sortColumn}
                            sortDirection={sortDirection}
                            progressPending={Loading}
                        />
                    }
                </Box>

                <Datatablepagination page={page} totalData={total} perpage={perpage} handelpagechange={handelpagechange} Loading={Loading} countlabel={'Doctors'} />
            </Card>
        </Box>
    );
};

export default DoctorList;
