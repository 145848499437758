import axios from "axios";
const DATA_API = process.env.REACT_APP_API_URL;



export const DisplayCounts = async () => {
  try {
    let res = await axios.get(`${DATA_API}/display_counts`,
      { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
    return res
  } catch (err) {
    return err
  }
};

export const MonthlyGraphCount = async () => {
  try {
    let res = await axios.get(`${DATA_API}/monthly_graphs`,
      { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
    return res
  } catch (err) {
    return err
  }
};

export const top_regionwise_graphs_piechart = async () => {
  try {
    let res = await axios.get(`${DATA_API}/top_regionwise_graphs`,
      { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') } })
    return res
  } catch (err) {
    return err
  }
};

export const DeviceList = async (id, type, status, page, filtertext) => {
  try {
    let { data } = await axios.get(`${DATA_API}/device_list?page=${page}&division_id=${id}&status=${status}&type=${type}&search=${filtertext}`,
      { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' } }
    )
    return data
  } catch (err) {
    return err
  }
}

// export const ProductList = async (id) => {

//   try {
//     let res = await axios.get(`${DATA_API}/device_list?division_id=${id}`)
//   } catch (err) {
//     return err
//   }
// };


export const EmployeeList = async (divi, filtertext, page, sortColumn, sortDirection) => {
  try {
    let { data } = await axios.post(`${DATA_API}/employee_list/${divi}?page=${page}`,
      {
        "search": filtertext,
        "column": sortColumn,
        "direction": sortDirection,
      },
      {
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' }
      })
    return data
  } catch (err) {
    return err
  }
}


export const DeviceTypeandStatus = async () => {
  try {
    let { data } = await axios.get(`${DATA_API}/device_type_status`,
      { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' } })
    return data
  } catch (err) {
    return err
  }
};


export const ExportExcel_device = async (id, type, status) => {
  try {
    await axios.get(`${DATA_API}/device_export?division_id=${id}&status=${status}&type=${type}`,
      {
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' },
        responseType: 'blob'
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `device${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
  } catch (err) {
    return err
  }
};


export const ExportExcel_employee = async (id) => {
  try {
    await axios.post(`${DATA_API}/employee_export/${id}`,
      {
        data: ''
      },
      {
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' },
        responseType: 'blob'
      })
      .then((response) => {
        const outputFilename = `employee_report${Date.now()}.xlsx`;
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();

      });
  } catch (err) {
    return err
  }
};

export const defaulter_report = async (divi, start_date, to_date, page, filterText, scale, sortColumn, sortDirection) => {
  //console.log({ divi, start_date, to_date, page, filterText, scale, token: localStorage.getItem('token') })
  try {
    let { data } = await axios.post(`${DATA_API}/defaulter_report/${divi}?page=${page}`,
      {
        "from_date": start_date,
        "to_date": to_date,
        "search": filterText,
        "column": sortColumn,
        "direction": sortDirection,
      },
      {
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' }
      })
    return data
  } catch (err) {
    return err
  }
};

export const employeewise_report = async (divi, start_date, to_date, page, filterText, scale, sortColumn, sortDirection) => {
  // console.log({ divi, start_date, to_date, page, filterText, scale, token: localStorage.getItem('token') })
  try {
    let { data } = await axios.post(`${DATA_API}/employeewise_report/${divi}?page=${page}`,
      {
        "from_date": start_date,
        "to_date": to_date,
        "search": filterText,
        "column": sortColumn,
        "direction": sortDirection,
      },
      {
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' }
      })
    return data
  } catch (err) {
    return err
  }
};


export const Defaulter_report_export = async (id, start, end, scale) => {
  try {
    await axios.post(`${DATA_API}/defaulter_report_export/${id}`,
      {
        "from_date": start,
        "to_date": end,
        "scale_id": scale
      },
      {
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' },
        responseType: 'blob'
      })
      .then((response) => {
        const outputFilename = `defaulters_reports${Date.now()}.xlsx`;
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
      });
  } catch (err) {
    return err
  }
};


export const Employeewise_report_export = async (id, start, end, scale) => {
  try {
    await axios.post(`${DATA_API}/employeewise_report_export/${id}`,
      {
        "from_date": start,
        "to_date": end,
        "scale_id": scale
      },
      {
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' },
        responseType: 'blob'
      })
      .then((response) => {
        const outputFilename = `employeewise_reports${Date.now()}.xlsx`;
        const url = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
      });
  } catch (err) {
    return err
  }
};


export const GetDoc_report_onClick = async (id, page, Divid, filterText, sortColumn, sortDirection) => {
  try {
    let { data } = await axios.post(`${DATA_API}/employeewise_doctor/${Divid}?page=${page}`,
      {
        "subscriber_id": id,
        "search": filterText,
        "column": sortColumn,
        "direction": sortDirection,
      },
      {
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' }
      });
    return data
  } catch (err) {
    console.log({ err })
  }
}


export const ChangeUserStatus = async (divi, id, status) => {
  try {
    let { data } = await axios.post(`${DATA_API}/update_employee_status/${divi}`,
      {
        "user_id": id,
        "status": !status
      },
      {
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') || '' }
      })
    return data
  } catch (err) {
    console.log({ err })
  }
}


export const AddEmployee_req = async (data, div) => {
  try {
    let Data = JSON.stringify(data);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${DATA_API}/add_employee/${div}`,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      },
      data: Data
    };

    let res = await axios.request(config);
    return res.data
  } catch (err) {
    console.log({ err })
    return err
  }
}


