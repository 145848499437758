import React, { useEffect, useState } from 'react';
import { Box, Flex, Spacer, Text, Button, Tooltip } from '@chakra-ui/react';

import { GetDoc_report_onClick } from 'views/admin/request/admin_request';
import { useNavigate, useParams } from 'react-router-dom';
import DataTableFrame from 'components/datatable/DataTableFrame';
import Datatablepagination from 'components/pagination/Datatablepagination';
import Card from 'components/card/Card';
import Search from 'components/Search/Search';
import LoadingComp from 'components/LodingComp/LoadingComp';
import BreadCrumb from 'components/navbar/Breadcrumb';
import { useLocation } from 'react-router-dom';
import { IoArrowBack } from "react-icons/io5";
import { useDebounce } from 'use-debounce'; 

function toTitleCase(str) {
    if (!str || typeof str !== 'string' || str.trim() === '') {
        return '-';
    }
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
}

const Doc_Report = () => {

    const location = useLocation();
    const { state } = location;

    let { id } = useParams();
    const Navigate = useNavigate();
    let { divisions } = JSON.parse(localStorage.getItem('user')) || [];

    let [DrList, setDrlist] = useState([]);
    let [page, setPage] = useState(1);
    let [perpage, setPerpage] = useState(1);
    let [total, setTotal] = useState(0);
    let [Divid, setDivid] = useState(divisions[0].division_id);
    let [filterText, setFilterText] = useState('');
    let [Loading, setLoading] = useState(false);
    const [sortColumn, setSortColumn] = useState('doc_name'); // Default sort column
    const [sortDirection, setSortDirection] = useState('asc'); // Default sort direction

    const [debouncedFilterText] = useDebounce(filterText, 500);



    const column = [
        {
            name: <Text as='b'>Dr Name</Text>,
            selector: row => toTitleCase(row?.doc_name) || '-',
            sortable: true,
            sortField: 'doc_name',
        },
        {
            name: <Text as='b'>Dr Code</Text>,
            selector: row => row?.doc_code || '-',
            sortable: true,
            sortField: 'doc_code',
        },
        {
            name: <Text as='b'>Dr Speciality</Text>,
            selector: row => toTitleCase(row?.doc_speciality) || '-',
            sortable: true,
            sortField: 'doc_speciality',
        },
        {
            name: <Text as='b'>Dr Mobile</Text>,
            selector: row => row?.doc_mobile || '-',
            sortable: true,
            sortField: 'doc_mobile',
        },
        {
            name: <Text as='b'>Region</Text>,
            selector: row => toTitleCase(row?.state_code) || '-',
            sortable: true,
            sortField: 'state_code',
        },
        {
            name: <Text as='b'>City</Text>,
            selector: row => toTitleCase(row?.city_code) || '-',
            sortable: true,
            sortField: 'city_code',
        },
        {
            name: <Text as='b' style={{ textAlign: 'center' }}>Total Tests</Text>,
            selector: row => row?.total_tests,
            sortable: true,
            cell: row => (
                <Text style={{ textAlign: 'center' }}>
                    {row?.total_tests || '-'}
                </Text>
            ),
            sortField: 'doc_mobile',
        },
        {
            name: <Text as='b' style={{ textAlign: 'center' }}>Total Camps</Text>,
            selector: row => row?.total_camps,
            sortable: true,
            cell: row => (
                <Text style={{ textAlign: 'center' }}>
                    {row?.total_camps || '-'}
                </Text>
            ),
            sortField: 'doc_mobile',
        },
        {
            name: <Text as='b' style={{ textAlign: 'center' }}>Last Camp Date</Text>,
            selector: row => row?.last_camp_date
            ,
            sortable: true,
            cell: row => (
                <Text style={{ textAlign: 'center' }}>
                    {row?.last_camp_date || '-'}
                </Text>
            ),
            sortField: 'doc_mobile',
        },
    ]

    const getDoctorList = async (type) => {
        try {
            if (type !== 'sort') {
                setLoading(true);
            }
            let data = await GetDoc_report_onClick(id, page, Divid, debouncedFilterText, sortColumn, sortDirection);
            if (data.status === 'success') {
                setDrlist(data.data.data);
                setPage(data.data.current_page);
                setPerpage(data.data.per_page);
                setTotal(data.data.total);
            };
            setLoading(false)
        } catch (err) {
            Navigate('auth/sign-in')
        }
    }


    useEffect(() => {
        setLoading(true)
        getDoctorList('regular')
    }, [page, Divid, debouncedFilterText]);

    useEffect(() => {
        getDoctorList('sort')
    }, [sortDirection]);

    const handleSort = (column, sortDirection) => {
        setSortColumn(column.sortField);
        setSortDirection(sortDirection);
    };

    const handelpagechange = (val) => {
        if (val === page || Loading) {
            return
        }
        setPage(val)
    };

    const handelSearch = (val) => {
        setFilterText(val)
    }


    return (
        <Box p='10px' pt={{ base: "60px", md: "80px", xl: "80px" }}>

            {/* <Box display={{ base: 'block', md: 'none' }} p='5px'>
                <BreadCrumb   />
            </Box> */}

            <Card>


                <Flex w='100%' gap='10px' overflow='auto' justifyContent='baseline'>

                    <Tooltip label={`Go back to employees`} fontSize='sm'>
                        <Button
                            onClick={() => Navigate(-1)} // Navigates to the previous page
                            variant='outline'
                            mr='2px'
                            p='2px'
                            size="sm"
                        >
                            <IoArrowBack />
                        </Button>
                    </Tooltip>
                    <Text pl='6px' fontSize='xl'>Doctors List  of  <b>{state?.name} </b> </Text>

                    <Spacer />
                    <Search filterText={filterText} handelSearch={handelSearch} />
                </Flex>


                <Box>
                    {
                        Loading ? <LoadingComp /> :
                            <DataTableFrame
                                data={DrList}
                                keyvalue='doc_code'
                                column={column}
                                onSort={handleSort} 
                                sortServer
                                sortColumn={sortColumn}
                                sortDirection={sortDirection}
                                progressPending={Loading}
                            />
                    }
                </Box>


                <Datatablepagination page={page} totalData={total} perpage={perpage} handelpagechange={handelpagechange} Loading={Loading} countlabel={'Doctors'} />

            </Card>
        </Box>
    )
}

export default Doc_Report;