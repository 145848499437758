import {
  Box,
  Icon,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, {
  useEffect,
  useState
} from "react";
import { useNavigate } from "react-router-dom";
import LoadingComp from "components/LodingComp/LoadingComp";
import CountUp from "react-countup";
import { DisplayCounts } from "../request/admin_request";
import { MonthlyGraphCount } from "../request/admin_request";
import {
  BsDeviceSsdFill,
  BsColumnsGap,
  BsBookmarkHeartFill
} from "react-icons/bs";
import { HiUserGroup } from "react-icons/hi";
import { AiOutlineSchedule, AiOutlineContacts } from "react-icons/ai";
import { TiPrinter } from "react-icons/ti";
import { PiToiletPaperDuotone } from "react-icons/pi";
import { FaPrescription } from "react-icons/fa";
import { motion } from "framer-motion";
import BreadCrumb from "components/navbar/Breadcrumb";
import ScalesData from "./components/Scales";
import MonthlyChart from "./components/MonthlyChart";
import PieCard from "./components/PieCard";
import axios from "axios";
const DATA_API = process.env.REACT_APP_API_URL;

const Icons = [
  HiUserGroup,
  BsColumnsGap,
  BsBookmarkHeartFill,
  AiOutlineSchedule,
  AiOutlineContacts,
  TiPrinter,
  PiToiletPaperDuotone
];


function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}

export default function UserReports() {

  const { system_designation, role, divisions } = JSON.parse(localStorage.getItem("user")) || "";
  const [dailyCounts, setDailyCounts] = useState({});
  const [monthlyChartData, setMonthlyChartData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
 
  const getContent = async () => {
    try {
      setLoading(true);
      let { data } = await DisplayCounts();
      if (data.status === "success") {
        setDailyCounts(data.data);
        setLoading(false);
      } else {
        navigate("/auth/sign-in");
      }
    } catch (err) {
      navigate("/auth/sign-in");
    }
  };



  useEffect(() => {
    getContent()
  }, []);

  const colors = [
    "green.700",
    "#CC0000", // red
    "teal.600", // Tomato
    "pink.700", // Lime
    "blue.500", // OrangeRed
    "yellow.700", // HotPink
    "gray",
    "#FFA500", // Orange
    "#FFFF00", // Yellow
    "#8A2BE2", // BlueViolet
  ];

  const color2 = [
    "green.200",
    "red.200", // Lime
    "teal.200", // Magenta
    "pink.200", // Cyan
    "blue.200", // OrangeRed
    "yellow.200", // HotPink
    "#D4D4D4",
    "#FFFF00", // Yellow
    "#00FF7F", // SpringGreen
    "#FFA500",
  ];

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };


  return (
    <Box
      p="20px"
      pt={{ base: "60px", md: "85px", xl: "85px" }}
      pb={{ base: "15px", md: "40px", xl: "40px" }}
    >
      <Box display={{ base: "block", md: "none" }} p="5px">
        <BreadCrumb />
      </Box>

      {loading ? (
        <LoadingComp />
      ) : (
        dailyCounts && (
          <>
            <SimpleGrid
              columns={{ base: 1, md: 3, lg: 4, "2xl": 5 }}
              gap="20px"
              mb="20px"

            >
              {dailyCounts &&
                Object.keys(dailyCounts).map((Keyname, i) => {
                  if (
                    role === "employee" &&
                    (Keyname === "devices" || Keyname === "employees")
                  ) {
                    return null;
                  } else if (Keyname === "brands") {
                    return null;
                  } else {
                    if (dailyCounts[Keyname] === 0) {  // return null if we have 0 value for elements
                      return
                    }
                    return (
                      <motion.div
                        key={i}
                        initial="hidden"
                        animate="visible"
                        variants={cardVariants}
                        transition={{ duration: 0.5, delay: i * 0.1 }}
                      >
                        <MiniStatistics
                          startContent={
                            <IconBox
                              w="56px"
                              h="56px"
                              p="10px"
                              bg={color2[i]}
                              icon={
                                <Icon
                                  w="30px"
                                  h="30px"
                                  as={Icons[i]}
                                  color={colors[i]}
                                />
                              }
                            />
                          }
                          name={toTitleCase(Keyname.split("_").join(" "))}
                          Color={colors[i]}
                          value={
                            <CountUp
                              start={Math.round(dailyCounts[Keyname] / 2)}
                              end={dailyCounts[Keyname]}
                            />
                          }
                        />
                      </motion.div>
                    );
                  }
                })}
            </SimpleGrid>
          </>
        )
      )}

      {dailyCounts?.brands && Object.keys(dailyCounts?.brands).length > 0 && (
        <>
          <Text mb="10px" fontWeight='bold' fontSize='24px' mt={{ sm: '10px', md: '30px' }} color='gray.600'>Brand Prescriptions</Text>
          <SimpleGrid
            columns={{ base: 1, md: 3, lg: 4, "2xl": 5 }}
            gap="20px"
            mb="20px"
          >
            {dailyCounts?.brands &&
              Object.keys(dailyCounts?.brands).map((Keyname, i) =>
              (
                <motion.div
                  key={i}
                  initial="hidden"
                  animate="visible"
                  variants={cardVariants}
                  transition={{ duration: 0.5, delay: i * 0.1 }}
                >
                  <MiniStatistics
                    startContent={
                      <IconBox
                        w="56px"
                        h="56px"
                        p="10px"
                        bg={color2[i]}
                        icon={
                          <Icon
                            w="30px"
                            h="30px"
                            as={FaPrescription}
                            color={colors[i]}
                          />
                        }
                      />
                    }
                    name={toTitleCase(Keyname.split("_").join(" "))}
                    Color={colors[i]}
                    value={
                      <CountUp
                        start={Math.round(dailyCounts?.brands[Keyname] / 2)}
                        end={dailyCounts?.brands[Keyname]}
                      />
                    }
                  />
                </motion.div>
              ))}
          </SimpleGrid>
        </>
      )}

      <ScalesData />

      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, "2xl": 2 }}
        gap="20px"
        mb="20px"
      >
        <MonthlyChart />
        {system_designation !== 'm3' ? <PieCard /> : null}
      </SimpleGrid>

    </Box>
  );
}
