import React, { useState } from 'react';
import { Box, Text, Grid, GridItem, Input, FormLabel, Button, Flex, useToast, } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { motion } from 'framer-motion';
import { useForm } from 'react-hook-form';
import { AddEmployee_req } from 'views/admin/request/admin_request';
import { useNavigate } from 'react-router-dom';

const MotionBox = motion(Box);
let { divisions } = JSON.parse(localStorage.getItem('user')) || [];

const AddEmployee = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  let toast = useToast();

  const onSubmit = async (data) => {
    setLoading(true);
    let res = await AddEmployee_req(data, divisions[0].division_id);
    if (res?.status === 'success') {
      toast({
        title: 'Employee added successfully.',
        status: 'success',
        position: 'top',
        isClosable: true,
      })
      setLoading(false)
      navigate('/admin/employee-list')
    } else {
      toast({
        // title: res.response.data.errors ,
        title:res.response.data.errors[Object.keys(res.response.data.errors)[0]] ,
        status: 'error',
        position: 'top',
        isClosable: true,
      })
      setLoading(false)
    }

  };

  // Define the fields in an array
  const fields = [
    { key: 'name', label: 'Name', placeholder: 'Enter name', id: 'name', required: true },
    { key: 'username', label: 'Username', placeholder: 'Enter username', id: 'username', required: true },
    { key: 'email', label: 'Email', placeholder: 'Enter email', id: 'email', type: 'email', required: true },
    { key: 'emp_code', label: 'Employee Code', placeholder: 'Enter employee code', id: 'emp_code', required: true },
    { key: 'mobile_number', label: 'Mobile', placeholder: 'Enter mobile', id: 'mobile_number', type: 'tel', required: true },
    { key: 'designation', label: 'Designation', placeholder: 'Enter designation', id: 'designation', required: true },
    // { key: 'systemDesignation', label: 'System Designation', placeholder: 'Enter system designation', id: 'system_designation' },
    { key: 'area', label: 'Area', placeholder: 'Enter area', id: 'area', required: false},
    { key: 'hq', label: 'HQ', placeholder: 'Enter HQ', id: 'hq', required: false },
    { key: 'region', label: 'Region', placeholder: 'Enter region', id: 'region', required: true },
    { key: 'zone', label: 'Zone', placeholder: 'Enter zone', id: 'zone', required: true },
    { key: 'manager', label: 'Manager', placeholder: 'Enter manager name', id: 'manager', required: true },
  ];

  return (
    <Card m='auto' mt='100px' w='98%'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Text fontSize='xl' mb='10px'>Add Employee Form</Text>

        {/* Grid Layout with 3 Equal Columns */}
        <Grid templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(3, 1fr)" }} gap={6}>
          {fields.map(({ key, label, placeholder, id, type, required }) => (
            <GridItem key={key} colSpan={1}>
              <MotionBox
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <FormLabel htmlFor={id}>
                  <Flex alignItems='center'>
                    <Text fontSize='12px'>{label}</Text>  {required ? <Text color='red' fontSize='15px'> *</Text> : null}
                  </Flex>
                </FormLabel>
                <Input
                  id={id}
                  placeholder={placeholder}
                  fontSize='14px'
                  type={type || 'text'}
                  {...register(key, {
                    required: required && `${label} is required`,
                    ...(key === 'email' && {
                      pattern: {
                        value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                        message: 'Invalid email address',
                      },
                    }),
                    ...(key === 'mobile_number' && {
                      pattern: {
                        value: /^[0-9]+$/g,
                        message: 'Mobile number must be digits only',
                      },
                      minLength: {
                        value: 10,
                        message: 'Mobile number must be exactly 10 digits',
                      },
                      maxLength: {
                        value: 10,
                        message: 'Mobile number must be exactly 10 digits',
                      },
                    }),
                  })}
                />
                {errors[key] && <Text fontSize='12px' color="red">{errors[key].message}</Text>}
              </MotionBox>
            </GridItem>
          ))}
        </Grid>

        <Button
          isLoading={loading}
           loadingText='Submitting'
          type="submit" colorScheme="teal" mt='20px'
        >Submit</Button>
      </form>
    </Card>
  );
};

export default AddEmployee;
