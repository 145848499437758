import React, { useEffect, useState } from 'react';
import { createContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const AuthContext = createContext()
const LOGIN_URL = process.env.REACT_APP_API_URL;
const DATA_API = process.env.REACT_APP_API_URL;

const Authantication = ({ children }) => {
  const [campplan, setCampplan] = useState(null);
  const [userTokens, setUserTokens] = useState(null);
  const [userid, setUserId] = useState(null)
  const Navigate = useNavigate();


  let loginAuth = async (obj) => {
    try {
      let { data } = await axios.post(`${LOGIN_URL}/login`, obj)
      let token = data.data.token;
      let user = data.data.user;
      setUserId(data.data.user.id);
      // console.log({user});
      localStorage.removeItem('token');
      localStorage.setItem('token', token);
      localStorage.removeItem('user')
      localStorage.setItem('user', JSON.stringify(user));
      return data.status

    } catch (err) {

      return err
    }

  }

  let logoutAuth = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.clear();
    setCampplan(null)
    setUserTokens(null)
    Navigate('/auth/sign-in')
  }

  const FetchDivision = async (id) => {
    try {
      const { data } = await axios.get(`${DATA_API}/division_detail/${id}`, {
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
      });

      const addEmpToken = data.data.meta.find(item => item.key === 'ADD_EMP');
      const endCampBtn = data.data.meta.find(item => item.key === 'CAMP_PLAN');

      if (endCampBtn) {
        // localStorage.setItem('CampPlan', endCampBtn.value);
        setCampplan(endCampBtn.value);
      } else {
        setCampplan('False');
      }

      if (addEmpToken) {
        // localStorage.setItem('userTokens', JSON.stringify({ addEmp: true }));
        setUserTokens(true);
      } else {
        // localStorage.setItem('userTokens', JSON.stringify({ addEmp: false }));
        setUserTokens('False');
      }

      return data;
    } catch (err) {
      console.error('Failed to fetch division:', err); // Improved error logging
      return err;
    }
  };

  useEffect(() => {
    if (userid) {
      FetchDivision(userid)
    }
  }, [])


  return (
    <AuthContext.Provider value={{ loginAuth, logoutAuth, campplan, FetchDivision, userTokens }}>
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, Authantication };