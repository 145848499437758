import React from "react";

// chakra imports
import {
  Box,
  Flex,
  Drawer,
  DrawerBody,
  Icon,
  DrawerOverlay,
  useDisclosure,
  DrawerContent
} from "@chakra-ui/react";
import Content from "components/sidebar/components/Content";
import {
  renderThumb,
  renderTrack,
  renderView,
} from "components/scrollbar/Scrollbar";
import { CgMenuGridO } from "react-icons/cg";
import { Scrollbars } from "react-custom-scrollbars-2";
import PropTypes from "prop-types";
import { TbReportAnalytics } from "react-icons/tb";


function Sidebar(props) {
  const { routes } = props;
  let variantChange = "0.2s linear";



  // SIDEBAR 
  return (
    <Box
      display={{ sm: "none", xl: "block" }}
      bg={`#12b1ae`}
      transition={variantChange}
    >
      <Content routes={routes} />
    </Box>
  );
}

// FUNCTIONS
export function SidebarResponsive(props) {

  // // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  const { routes } = props;

  return (
    <Flex display={{ sm: "flex", xl: "none" }} alignItems='center'>
      <Flex ref={btnRef} w='max-content' onClick={onOpen}>
        <Icon
          as={CgMenuGridO}
          color='black'
          m='10px'
          fontSize='30px'
          _hover={{ cursor: "pointer" }}
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement={document.documentElement.dir === "rtl" ? "right" : "left"}
        finalFocusRef={btnRef}>
        <DrawerOverlay />
        <DrawerContent w='285px' maxW='285px'>
          <DrawerBody maxW='285px' px='0rem' pb='0' bg={`#232627`}>
            <Scrollbars
              autoHide
              renderTrackVertical={renderTrack}
              renderThumbVertical={renderThumb}
              renderView={renderView}>
              <Content routes={routes} onClose={onClose} />
            </Scrollbars>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
// PROPS

Sidebar.propTypes = {
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};

export default Sidebar;
