// chakra imports
import { Flex, Stack } from "@chakra-ui/react";

// Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import React from "react";
import UserCard from "./UserCard";

// FUNCTIONS
function SidebarContent({ routes, minvalue, onClose }) {

  return (
    <Flex
      bg={`#12b1ae`}
      direction='column'
      gap='1px'
      height='100vh'
      overflow='scroll'
      css={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "&": {
          scrollbarWidth: "none",
        },
      }}
    >

      <Brand minvalue={minvalue} />

      <Stack direction='column' m='auto' w='100%' pl='10px' h='100%'>
        <Links routes={routes} minvalue={minvalue} onClose={onClose} />
      </Stack>

      <UserCard />
    </Flex>
  );
}

export default SidebarContent;
