import { Box, Text, Flex } from '@chakra-ui/react';
import React from 'react';
import { motion } from 'framer-motion';

const UserCard = () => {
    const { name, role, company_name, designation } = JSON.parse(localStorage.getItem('user')) || {};

    const cardVariants = {
        hidden: { opacity: 0, x: -50 },
        visible: { opacity: 1, x: 0 },
    };

    return (
        <Flex w="90%" justify="center" m='auto'>
            <motion.div
                initial="hidden"
                animate="visible"
                variants={cardVariants}
                transition={{ duration: 0.5 }}
                style={{ width: '100%' }}
            >
                <Box
                    p="5px"
                    px='20px'
                    mb="20px"
                    w="100%"
                    borderRadius="md"
                    bg="teal.500"
                    border="1px solid teal.600"
                    color="white"
                    boxShadow="lg"
                >
                    <Text as="b" fontSize="18px" letterSpacing="wider">
                        {name && name[0].toUpperCase() + name.substr(1).split('_').join(' ')}
                    </Text>
                    <Text fontSize="12px">
                        Division: {company_name}
                    </Text>
                    <Text fontSize="12px">
                        Designation: {designation}
                    </Text>
                </Box>
            </motion.div>
        </Flex>
    );
};

export default UserCard;
