import React from 'react'
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialogCloseButton,
    Button,
  } from "@chakra-ui/react";



export const AlertMsg = ({setIsOpen, isOpen, msg, header}) => {
    const cancelRef = React.useRef()


    return (
        <>
        {/* <Button onClick={()=>setIsOpen(true)}>Discard</Button> */}
        <AlertDialog
          motionPreset='slideInBottom'
          leastDestructiveRef={cancelRef}
          onClose={()=>setIsOpen(false)}
          isOpen={isOpen}
          isCentered
        >
          <AlertDialogOverlay />
  
          <AlertDialogContent>
            <AlertDialogHeader>{header}</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
             {msg.message}
            </AlertDialogBody>
            <AlertDialogFooter>
            
              <Button colorScheme='teal' ml={3} onClick={()=>setIsOpen(false)}>
                OK
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </>
    )
}

export default AlertMsg